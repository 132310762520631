// Third Parties
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { add } from 'date-fns'
import { Controller } from 'react-hook-form'
// Types
import { HotelSearchParams } from '@reward-platform/ancillaries-schemas/hotel'
import {
  AutoCompleteLocationOption,
  AutoCompleteWithMobileModal,
  RangeDatePicker,
} from '@reward-platform/lift/components'
import { useQueryParams } from '~/hooks/useQueryParams/useQueryParams'
import {
  Action,
  Room,
} from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoomPicker.types'
import { ContentfulCard } from '@reward-platform/shared-schemas'
// Utils
import { createAutoCompleteLocationSource } from '~/utils/createAutoCompleteSourceFromLocation'
import { getLocations } from '~/services/locationService'
// Hooks
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { Box, chakra, useMultiStyleConfig, Button } from '@chakra-ui/react'
import {
  gtmSearchSubmit,
  refineSearchSessionStorage,
} from '~/utils/googleTagManager/googleTagManagerHelperFunctions'
import { useExperimentFlag, useFeatureFlag } from '~/hooks/useFeatureFlag'
import { useDateRangeState, useDestinationState, useHotelSearchForm } from './useHotelSearch'
import { useParseQueryStateToFormState } from '../HotelResults/useQueryToFormState'
import { useTranslations } from './HotelSearch.translations'
import { addRoom, removeRoom, setChildAge, setTravelers } from './TravelerRoomPicker/roomReducer'
// Components
import { TravelerRoomPicker } from './TravelerRoomPicker'
import RecentSearches, { getRecentSearchesFromLocalStorage } from '../RecentSearches/RecentSearches'
import PopularDestinations from '../PopularDestinations'
import { PopularDestinationType } from '../PopularDestinations/PopularDestinations'

const initialRooms: Room[] = [
  {
    roomNumber: 1,
    adults: 2,
    children: 0,
  },
]

type SearchHotelFormProps = {
  trendingDestinations?: ContentfulCard[]
}

function SearchHotelForm({ trendingDestinations }: SearchHotelFormProps): JSX.Element {
  const partner = usePartner()
  const { dateLabel, t } = useTranslations()
  const { pathname } = useRouter()

  const styles = useMultiStyleConfig('SearchHotelForm', {})

  // Read the query string and unsafely parse
  const { query } = useQueryParams<HotelSearchParams>()

  const { userLocale } = useUser()

  // Build form state object from query parameters then setup form
  const parsedFormValues = useParseQueryStateToFormState(query)
  // Use pre-defined defaults if not parsed from query successfully
  const defaultFormValues = parsedFormValues.success
    ? parsedFormValues.data
    : {
        destination: {
          type: '',
          id: '',
          fullName: '',
          name: '',
        },
        rooms: initialRooms,
        startDateTime: add(new Date(), { hours: 28 }),
        endDateTime: add(new Date(), { hours: 52 }),
      }

  const { form, errors, handleFormSubmit } = useHotelSearchForm(defaultFormValues)
  const destination = useDestinationState(form, defaultFormValues.destination)
  const autocompleteItems = useMemo(
    () => createAutoCompleteLocationSource(destination.locations),
    [destination.locations]
  )

  const dates = useDateRangeState(form, [
    defaultFormValues.startDateTime,
    defaultFormValues.endDateTime,
  ])
  const { control, handleSubmit } = form

  const onSubmitClick = () => {
    refineSearchSessionStorage(pathname)
    gtmSearchSubmit('hotels')
  }

  const recentSearchesData = getRecentSearchesFromLocalStorage()
  const recentSearchesEnabled = useFeatureFlag('hotels-recent-searches')
  const popularDestinationsEnabled = useExperimentFlag('popular-destinations')

  // Popular destinations & recent searches
  const [popularDestinations, setPopularDestinations] = useState<PopularDestinationType[]>([])

  const mapLocationToDestination = useCallback(
    async (_destination: ContentfulCard) => {
      if (!_destination.heading) {
        return
      }
      const locations = await getLocations(partner.code, 'HOTEL', _destination.heading)
      const location = (locations ?? [])[0]

      setPopularDestinations((prev) => {
        if (prev.find((x) => x.id === location?.id)) {
          return prev
        }
        return [
          ...prev,
          {
            ..._destination,
            ...location,
          },
        ]
      })
    },
    [partner.code]
  )

  useEffect(() => {
    trendingDestinations?.forEach((dest) => mapLocationToDestination(dest))
  }, [trendingDestinations, mapLocationToDestination])

  const showRecentSearches =
    recentSearchesEnabled && !destination.searchString && !!recentSearchesData?.length

  const showPopularDestinations =
    popularDestinationsEnabled.value && !destination.searchString && !!popularDestinations?.length

  const destinationInputRef = useRef<HTMLInputElement | null>(null)

  const handleDestinationClick = (_destination: PopularDestinationType) => {
    destination.handleDestinationClick(_destination)
  }

  let containerHeightStyle
  if (showPopularDestinations || showRecentSearches) {
    containerHeightStyle = styles.maxHeightUnset
  }

  return (
    <Box
      as="form"
      __css={styles.container}
      sx={containerHeightStyle}
      autoComplete="off"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        control={control}
        name="destination"
        render={() => (
          <Box __css={styles.autocompleteWithMobileModal}>
            <AutoCompleteWithMobileModal
              ref={destinationInputRef}
              name="destination"
              label={t.destinationLabel}
              value={destination.searchString}
              items={autocompleteItems}
              onInputFocus={destination.onInputFocus}
              onInputValueChange={(value) => {
                destination.handleAutocompleteChange(value.inputValue ?? '')
              }}
              onSelectedItemChange={(value) => {
                destination.handleSuggestionClick(value.selectedItem?.value || '')
              }}
              error={errors.getDestinationError()}
            >
              <Box
                __css={styles.destinationAutocompleteContainer}
                sx={showRecentSearches && showPopularDestinations ? styles.twoColumns : undefined}
              >
                {!destination.isLoading && !destination.noResultsFound ? (
                  autocompleteItems.map((item, index) => {
                    return <AutoCompleteLocationOption key={item.key} item={item} index={index} />
                  })
                ) : (
                  <chakra.div __css={styles.destination}>
                    {destination.isLoading ? (
                      <chakra.p>{t.search}</chakra.p>
                    ) : (
                      <chakra.p>
                        {t.noResults} <b>"{destination.searchString}"</b>
                      </chakra.p>
                    )}
                  </chakra.div>
                )}
                {showRecentSearches ? <RecentSearches data={recentSearchesData} /> : null}
                {showPopularDestinations ? (
                  <PopularDestinations
                    destinations={popularDestinations}
                    onDestinationClick={handleDestinationClick}
                    displayInline={!showRecentSearches}
                  />
                ) : null}
              </Box>
            </AutoCompleteWithMobileModal>
          </Box>
        )}
      />
      <Box __css={styles.dates}>
        <RangeDatePicker
          startLabel={dateLabel.checkIn}
          endLabel={dateLabel.checkOut}
          values={{ startDateTime: dates.startDate, endDateTime: dates.endDate || undefined }}
          onChange={({ startDateTime: startDate, endDateTime: endDate }) =>
            dates.handleDateChange([startDate || dates.startDate, endDate || null])
          }
          minDate={dates.minDate}
          maxDate={dates.maxDate}
          maxEndDate={dates.maxEndDate}
          endDateSelectable
          locale={userLocale}
          errors={errors}
        />
      </Box>
      <Controller
        name="rooms"
        control={control}
        render={({ field: { onChange, value } }) => {
          // TODO Temporary solution until TravelerRoomPicker is refactored, don't use elsewhere if possible
          const dispatch: Dispatch<Action> = (action: Action) => {
            switch (action.type) {
              case 'ADD_ROOM':
                onChange(addRoom(value, action))
                break
              case 'REMOVE_ROOM':
                onChange(removeRoom(value, action))
                break
              case 'SET_TRAVELERS':
                onChange(setTravelers(value, action))
                break
              case 'SET_CHILD_AGE':
                onChange(setChildAge(value, action))
                break
              default:
                break
            }
          }

          return (
            <TravelerRoomPicker
              sx={styles.travellerRoomPicker}
              rooms={value}
              dispatch={dispatch}
              errors={errors.getFieldError('rooms')}
            />
          )
        }}
      />
      <Button
        sx={styles.submitBtn}
        variant="primary"
        type="submit"
        size="lg"
        onClick={onSubmitClick}
      >
        Search Hotels
      </Button>
    </Box>
  )
}

export default SearchHotelForm
