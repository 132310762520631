import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { ExperienceSearchParams } from '@reward-platform/ancillaries-schemas/experience'
import { createAutoCompleteLocationSource } from '~/utils/createAutoCompleteSourceFromLocation'
import {
  AutoCompleteLocationOption,
  AutoCompleteWithMobileModal,
  RangeDatePicker,
} from '@reward-platform/lift/components'
import { useQueryParams } from '~/hooks/useQueryParams/useQueryParams'
import { useUser } from '~/context/user'
import { Box, chakra, useMultiStyleConfig, Button } from '@chakra-ui/react'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { useExperiencePageData } from '~/hooks/useExperiencePageData/useExperiencePageData'
import { useQueryStateToFormState } from '../ExperienceResults/useQueryToFormState'
import {
  useDateRangeState,
  useDestinationState,
  useExperienceSearchForm,
} from './useExperienceSearch'
import { useTranslations } from './SearchExperienceForm.translations'

const SearchExperienceForm = React.forwardRef<
  HTMLButtonElement,
  { buttonText: string; initialSearch?: boolean }
>((props: { buttonText: string; initialSearch?: boolean }, toggleRef): JSX.Element => {
  const { buttonText, initialSearch = true } = props
  const { t } = useTranslations()
  const styles = useMultiStyleConfig('SearchExperienceForm', {})
  const { query } = useQueryParams<ExperienceSearchParams>()
  const { userLocale } = useUser()
  const defaultValues = useQueryStateToFormState(query)
  const fieldName: keyof ExperienceSearchParams = 'destination'

  const { form, errors, handleFormSubmit } = useExperienceSearchForm(defaultValues, toggleRef)

  const destination = useDestinationState(form, defaultValues.destination)
  const autocompleteItems = useMemo(
    () => createAutoCompleteLocationSource(destination.locations),
    [destination.locations]
  )

  const dates = useDateRangeState(form, [defaultValues.startDateTime, defaultValues.endDateTime])

  const { control, handleSubmit } = form
  const { fromDate, toDate } = useExperiencePageData()

  const trackExperienceSearchDefaultObject = {
    nav_type: 'cta',
    nav_action: 'search again',
    from_date: fromDate,
    to_date: toDate,
    destination: destination.fullName,
  }

  const trackExperienceSearchButton = () => {
    if (initialSearch) {
      trackExperienceSearchDefaultObject.nav_action = 'search_experiences'
    }
    pushEventToDataLayer('navigation', trackExperienceSearchDefaultObject)
  }

  return (
    <Box
      as="form"
      __css={styles.container}
      autoComplete="off"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Controller
        control={control}
        name={fieldName}
        render={() => (
          <AutoCompleteWithMobileModal
            gridArea="var(--search-experience-location)"
            name={fieldName}
            label={t.destinationLabel}
            value={destination.searchString}
            items={autocompleteItems}
            onInputFocus={destination.onInputFocus}
            onInputValueChange={(val) => {
              return destination.handleAutocompleteChange(val.inputValue ?? '')
            }}
            onSelectedItemChange={(value) => {
              destination.handleSuggestionClick(value.selectedItem?.value || '')
            }}
            error={errors.getDestinationError()}
          >
            {
              // eslint-disable-next-line no-nested-ternary
              destination?.isLoading ? (
                <chakra.div __css={styles.destination}>{t.search}</chakra.div>
              ) : destination.noResultsFound ? (
                <chakra.div __css={styles.destination}>
                  {t.noResults} <b>"{destination.searchString}"</b>
                </chakra.div>
              ) : (
                autocompleteItems.map((item, index) => {
                  return <AutoCompleteLocationOption key={item.key} item={item} index={index} />
                })
              )
            }
          </AutoCompleteWithMobileModal>
        )}
      />
      <Box gridArea="dates">
        <RangeDatePicker
          startLabel={t.fromExperienceStart}
          endLabel={t.toExperienceEnd}
          onChange={({ startDateTime: startDate, endDateTime: endDate }) =>
            dates.handleDateChange([startDate || dates.startDate, endDate || null])
          }
          values={{ startDateTime: dates.startDate, endDateTime: dates.endDate || undefined }}
          minDate={dates.minDate}
          maxDate={dates.maxDate}
          endDateSelectable
          locale={userLocale}
          errors={errors}
        />
      </Box>

      <Button
        sx={styles.submitBtn}
        gridArea="search"
        variant="primary"
        type="submit"
        onClick={() => trackExperienceSearchButton()}
      >
        {buttonText}
      </Button>
    </Box>
  )
})

export default SearchExperienceForm
