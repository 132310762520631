import React, { ReactElement } from 'react'
import { useRouter } from 'next/router'
import { Box, Text, useMultiStyleConfig, Image, Button } from '@chakra-ui/react'
import { RichText } from '@reward-platform/lift/components'
import { useAviosOnlyPromotion } from '~/hooks/useAviosOnlyPromotion/useAviosOnlyPromotion'
import { pushEventToDataLayer } from '~/utils/googleTagManager'

const AviosShape = () => (
  <svg
    width="628"
    height="628"
    viewBox="0 0 628 628"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M612.991 243.706C591.181 205.866 395.741 15.0856 158.651 0.205601C117.321 -2.4144 78.1012 20.2056 61.1213 56.6356C15.8713 153.706 -39.4087 370.796 40.6313 568.686C55.2413 604.826 88.5612 627.276 127.571 627.276C131.861 627.276 136.231 627.006 140.621 626.456C411.271 593.206 584.961 405.166 614.361 344.076C625.381 321.146 637.211 285.796 612.991 243.706Z" />
  </svg>
)

const FlightAviosOnlyBanner = (): ReactElement | null => {
  const router = useRouter()
  const styles = useMultiStyleConfig('FlightAviosOnlyBanner', {})

  const { aviosOnlyPromotionContent, isLoading } = useAviosOnlyPromotion()

  if (!aviosOnlyPromotionContent || isLoading) {
    return null
  }

  const handlePrefillSearchForm = () => {
    router.push({
      query: {
        tab: 'flights',
        departureDate: aviosOnlyPromotionContent.outboundDate,
        returnDate: aviosOnlyPromotionContent.inboundDate,
        fromAirportCode: aviosOnlyPromotionContent.fromAirportCode,
        toAirportCode: aviosOnlyPromotionContent.toAirportCode,
        oneWayOnly: false,
      },
    })

    pushEventToDataLayer('navigation', {
      nav_type: 'cta',
      nav_action: 'avios-only reward flights book now',
    })
  }

  return (
    <Box sx={styles.aviosOnlyBanner}>
      <Box sx={styles.container}>
        <Image
          sx={styles.backgroundImage}
          src={aviosOnlyPromotionContent.image.file?.url}
          alt={aviosOnlyPromotionContent.image.title}
        />
        <Box sx={styles.aviosShape} />
        <Box sx={styles.aviosShape}>
          <AviosShape />
        </Box>
        <Box sx={styles.promoBanner}>
          <Box sx={styles.content}>
            <Box sx={styles.textContainer}>
              <Text sx={styles.introText}>{aviosOnlyPromotionContent.introduction}</Text>
              <Text sx={styles.titleText}>{aviosOnlyPromotionContent.title}</Text>
              <Box __css={styles.mainText}>
                {aviosOnlyPromotionContent && (
                  <RichText document={aviosOnlyPromotionContent.mainText} />
                )}
              </Box>
            </Box>
            <Button
              variant="secondary"
              type="button"
              onClick={handlePrefillSearchForm}
              sx={styles.button}
            >
              {aviosOnlyPromotionContent.buttonLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FlightAviosOnlyBanner
