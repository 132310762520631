import { useCallback, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import { SearchTabQuery, SearchTabQuerySchema } from '@reward-platform/shared-schemas'

const FALLBACK_SLUG = SearchTabQuerySchema.Enum.hotels

const useSearchPageRouter = (tabs?: SearchTabQuery[]) => {
  const router = useRouter()

  const flightsEnabled = useFeatureFlag('flights')
  const carHireEnabled = useFeatureFlag('car-hire')
  const experiencesEnabled = useFeatureFlag('experiences')
  const enabledTabs = useMemo(
    () => ({
      [SearchTabQuerySchema.Enum.hotels]: true,
      [SearchTabQuerySchema.Enum.flights]: flightsEnabled,
      [SearchTabQuerySchema.Enum.carhire]: carHireEnabled,
      [SearchTabQuerySchema.Enum.experiences]: experiencesEnabled,
    }),
    [carHireEnabled, experiencesEnabled, flightsEnabled]
  )

  const isTabEnabled = useCallback((slug: SearchTabQuery) => !!enabledTabs[slug], [enabledTabs])

  const defaultTab = useMemo(() => tabs?.find((tab) => isTabEnabled(tab)), [isTabEnabled, tabs])

  const validateTabSlug = useCallback(
    (slug?: string) => {
      const safeStringParse = SearchTabQuerySchema.safeParse(slug)
      if (safeStringParse.success && isTabEnabled(safeStringParse.data)) {
        return safeStringParse.data
      }
      return defaultTab ?? FALLBACK_SLUG
    },
    [defaultTab, isTabEnabled]
  )

  const selectedTab = useMemo(
    () => validateTabSlug(router.query.tab?.toString()),
    [router.query.tab, validateTabSlug]
  )

  const setSelectedTab = useCallback(
    (tabToSelect: string, includeQuery?: boolean) => {
      const tab = validateTabSlug(tabToSelect)
      const additionalQuery = includeQuery ? router.query : {}
      router.push({
        pathname: router.pathname || '/',
        query: { ...additionalQuery, tab },
      })
    },
    [router, validateTabSlug]
  )

  useEffect(() => {
    // Updates url tab query
    if (defaultTab && router.query.tab !== selectedTab) {
      setSelectedTab(selectedTab)
    }
  }, [defaultTab, router.query.tab, selectedTab, setSelectedTab])

  return { selectedTab, setSelectedTab, isTabEnabled }
}

export default useSearchPageRouter
