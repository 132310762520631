import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'

import { usePartner } from '~/context/partner'
import { getSearchPageContent } from '~/services/contentService'
import { ONE_HOUR } from '~/utils/datetime'
import { ContentfulSearchContent, SearchTabQuery } from '@reward-platform/shared-schemas'

const useSearchPageContent = () => {
  const router = useRouter()
  const partner = usePartner()

  const { data: searchPageData } = useQuery({
    queryKey: ['searchPage'],
    queryFn: () => getSearchPageContent(partner.tag, router.locale),
    gcTime: ONE_HOUR,
    staleTime: ONE_HOUR,
    meta: {
      notifyUser: false,
      errorMessage: 'Content Error - could not get search page content',
    },
  })

  const allTabContent = useMemo(() => searchPageData?.products || [], [searchPageData])
  const tabContentRecord = useMemo<Record<string, ContentfulSearchContent>>(
    () =>
      allTabContent.reduce((acc, tab) => {
        if (tab.product) {
          return { ...acc, [tab.product]: tab }
        }
        return acc
      }, {}),
    [allTabContent]
  )

  const getTabContentByProductSlug = useCallback(
    (slug: SearchTabQuery): ContentfulSearchContent | undefined => tabContentRecord[slug],
    [tabContentRecord]
  )

  return { allTabContent, getTabContentByProductSlug }
}

export default useSearchPageContent
