import { IconName } from '@reward-platform/lift/components/Icon/Icon.types'
import { TabContentProps } from '@reward-platform/lift/components/Tabs'
import {
  ContentfulSearchContent,
  ContentfulSearchContentProduct,
  SearchTabQuery,
  SearchTabQuerySchema,
} from '@reward-platform/shared-schemas'

type TabPropsConfig<K = string> = { slug: K; icon: IconName; fallbackTitle: string }
export const SEARCH_TAB_PROPS_RECORD: Record<
  ContentfulSearchContentProduct,
  TabPropsConfig<SearchTabQuery>
> = {
  [SearchTabQuerySchema.Enum.flights]: {
    slug: SearchTabQuerySchema.Enum.flights,
    icon: 'flights-tab',
    fallbackTitle: 'Flights',
  },
  [SearchTabQuerySchema.Enum.hotels]: {
    slug: SearchTabQuerySchema.Enum.hotels,
    icon: 'rp-hotel',
    fallbackTitle: 'Hotels',
  },
  [SearchTabQuerySchema.Enum.carhire]: {
    slug: SearchTabQuerySchema.Enum.carhire,
    icon: 'carhire_tab',
    fallbackTitle: 'Car Hire',
  },
  [SearchTabQuerySchema.Enum.experiences]: {
    slug: SearchTabQuerySchema.Enum.experiences,
    icon: 'experiences-tab',
    fallbackTitle: 'Experiences',
  },
}

export const EXTERNAL_LINK_PROPS_RECORD: Record<string, TabPropsConfig> = {
  wineflyer: { slug: 'wineflyer', icon: 'bar', fallbackTitle: 'Wine' },
}

const getFallbackLinkProps = ({ fallbackLink }: ContentfulSearchContent) =>
  fallbackLink
    ? {
        href: fallbackLink,
        variant: 'link',
        isVisible: true,
      }
    : {
        isVisible: false,
      }

const getExternalLinkProps = (searchContent: ContentfulSearchContent) => {
  const externalLinkProps = EXTERNAL_LINK_PROPS_RECORD[searchContent.identifier] || undefined
  if (externalLinkProps) {
    const { slug, icon, fallbackTitle } = externalLinkProps
    const title = searchContent.productLabel ?? fallbackTitle
    return {
      title,
      slug,
      icon,
      ...getFallbackLinkProps(searchContent),
    }
  }
  return null
}

const getTabContentProps = (searchContent: ContentfulSearchContent): TabContentProps | null => {
  const productKey = searchContent.product
  if (!productKey) {
    return null
  }
  const { slug, icon, fallbackTitle } = SEARCH_TAB_PROPS_RECORD[productKey]
  const title = searchContent.productLabel ?? fallbackTitle
  return {
    slug,
    title,
    icon,
    href: `/?tab=${slug}`,
    variant: 'tab',
  }
}

export const getTabPropsFromContent = (
  searchContent: ContentfulSearchContent,
  isProductEnabled = true
): TabContentProps | null => {
  const tabProps = getTabContentProps(searchContent)
  const fallbackLinkProps = getFallbackLinkProps(searchContent)

  if (!tabProps) {
    return getExternalLinkProps(searchContent)
  }

  if (isProductEnabled) {
    return { ...tabProps, isVisible: true }
  }

  if (fallbackLinkProps.isVisible) {
    return { ...tabProps, ...fallbackLinkProps }
  }

  return { ...tabProps, isVisible: false }
}
