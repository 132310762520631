import { IconNameType } from '@reward-platform/ancillaries-schemas/common'
import { Location } from '@reward-platform/ancillaries-schemas/location'
import { LocationOptionProps } from '@reward-platform/lift/components/AutoComplete/components/AutoCompleteLocationOption'
import { IconName } from '@reward-platform/lift/components/Icon/Icon.types'
import { uniqueId } from 'lodash'

export type AutoCompleteSource = {
  key: string
  title: string
  subtitle?: string
  icon: IconNameType
}

const getIcon = (fullName: string, type?: string): IconName => {
  if (!type) {
    return 'pin'
  }

  const formattedType = type.toLowerCase()

  if (fullName?.includes('Hotel')) {
    return 'bed-outline'
  }
  switch (formattedType) {
    case 'point_of_interest':
      return 'point-of-interest'
    case 'high_level_region':
      return 'high-level'
    case 'property':
      return 'home'
    case 'airport':
      return 'flights-tab'
    case 'neighborhood':
      return 'two-people'
    case 'province_state':
      return 'state'
    case 'railway_bus_station':
      return 'train2'
    case 'luxury_car_rental_station':
      return 'star2'
    case 'seaport_dock':
      return 'seaport-dock'
    case 'multi_city_vicinity':
    case 'city_downtown':
    case 'downtown':
    case 'downtown_off_airport':
    case 'delivery_collection_unit':
    case 'unmanned':
    case 'exhibition_conference_centre':
    case 'garage_hotel':
    case 'hq':
      return 'city-multi'
    default:
      return 'pin'
  }
}

export const createAutoCompleteSourceFromLocation = (
  locations: void | Location[] | undefined
): AutoCompleteSource[] =>
  locations?.map(({ fullName, id, type }) => {
    const splitIndex = fullName.indexOf(',')
    return {
      key: id,
      title: splitIndex > 0 ? fullName.substring(0, splitIndex) : fullName,
      subtitle: splitIndex > 0 ? fullName.substring(splitIndex + 1).trim() : '',
      icon: getIcon(fullName, type),
    }
  }) || []

export const createAutoCompleteLocationSource = (
  locations: void | (Location & { key?: string })[] | undefined,
  generateNewId?: boolean
): LocationOptionProps[] =>
  locations?.map((location) => {
    const { fullName, type, id } = location
    const newKey = uniqueId()
    location.key = newKey
    const splitIndex = fullName.indexOf(',')
    return {
      key: newKey,
      value: generateNewId ? newKey : id,
      icon: getIcon(fullName, type),
      label: fullName,
      title: splitIndex > 0 ? fullName.substring(0, splitIndex) : fullName,
      subtitle: splitIndex > 0 ? fullName.substring(splitIndex + 1).trim() : '',
    }
  }) || []
