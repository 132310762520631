import { useIntl } from 'react-intl'

export function useTranslations() {
  const intl = useIntl()
  const t = {
    destinationLabel: intl.formatMessage({
      id: 'destination-label',
      defaultMessage: 'Destination',
    }),
    searchExperiences: intl.formatMessage({
      id: 'search-experiences',
      defaultMessage: 'Search Experiences',
    }),
    fromExperienceStart: intl.formatMessage({
      id: 'from-experience-start',
      defaultMessage: 'From',
    }),
    toExperienceEnd: intl.formatMessage({
      id: 'to-experience-end',
      defaultMessage: 'To',
    }),
    search: intl.formatMessage({ id: 'search-label', defaultMessage: 'Searching...' }),
    noResults: intl.formatMessage({ id: 'no-results', defaultMessage: 'No results found for ' }),
  }
  return { t }
}
